<template>
  <div>
    <div class="search" style="display: flex;justify-content: space-between;">
      <div>
        <div class="a-inline-block">
          <label for="">员工</label>
          <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择负责人" />
          <!-- <a-select size="large" v-model="key" placeholder="请选择执行人">
                    <a-select-option
                        v-for="(item, index) in clientList"
                        :key="index"
                        :value="item.userid"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select> -->

          <!-- <a-input
                    v-model="key"
                    size="large"
                    placeholder="请输入员工姓名"
                /> -->
        </div>
        <div class="a-inline-block">
          <label for="">时间</label>
          <a-select size="large" v-model="type">
            <a-select-option :value="1">年</a-select-option>
            <a-select-option :value="2">月</a-select-option>
            <a-select-option :value="3">周</a-select-option>
          </a-select>
        </div>

        <div class="a-inline-block">
          <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
          <a-button size="large" @click="reset">重置</a-button>
        </div>
      </div>
      <a-button class="addBtn" size="large" type="primary" style="width:auto;" @click="delAll">批量删除</a-button>
      <!-- <a-button size="large" type="primary" @click="addTarget" style="width:auto">添加个人目标</a-button> -->
    </div>
    <a-table  :row-selection="rowSelection" :rowKey="record=> record.id" :columns="columns" :data-source="data" class="table" :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Action" slot-scope="text, record">
        <!-- <a
                    class="action"
                    href="javascript:;"
                    @click="showDetail(record.id)"
                    >查看</a
                > -->
        <a class="action" href="javascript:;" @click="showDetail(record.id,2)">编辑</a>

        <!-- <a-popconfirm title="确定要关闭吗?" @confirm="() => off(record.id)">
                  <a class="action del" href="javascript:;">关闭</a>
              </a-popconfirm> -->
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>

  </div>
</template>
<script>
let timeOnj = [
  { key: 1, label: "年" },
  { key: 2, label: "月" },
  { key: 3, label: "周" },
];
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import moment from "moment";
import { requestXml, getDateTime } from "../../../assets/js/request";
export default {
  components: {
    Staff,
  },
  data() {
    return {
      clientList: [], //员工列表
      staff_id: "", //员工id

      key: "",
      type: null,
      taskType: [
        "打电话",
        "发短信",
        "发微信",
        "发邮件",
        "分享文章",
        "分享表单",
        "分享文件",
        "分享商品",
        "客户拜访",
        "其他",
      ],
      status: null,
      taskStatus: ["取消", "新建", "跟进中", "完成"],
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        { title: "目标的执行人", dataIndex: "stname", key: "7" },
        { title: "时间类型", dataIndex: "timetype", key: "6" },
        {
          title: "目标执行时间",
          dataIndex: "createtime",
          // width: 200,
          key: "8",
        },
        { title: "新客数", dataIndex: "newcustomers", key: "2" },
        { title: "签单数", dataIndex: "signlistnum", key: "1" },
        { title: "签单金额", dataIndex: "signlistmoney", key: "3" },
        { title: "回款数", dataIndex: "paymentnum", key: "4" },
        { title: "回款金额", dataIndex: "paymentmoney", key: "5" },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "Action" },
        },
      ],
      data: [],
      staffFlag: false,
      onStaff: {
        name: "",
        userid: "",
      },
      selectedRows: "",
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        // showTotal: total => `Total ${total} items`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
    };
  },
  mounted: function () {
    this.getTask();
    this.getClientList();
  },
  methods: {
    // 获取 简化成员列表
    getClientList() {
      requestXml("/scrm/Staff/getMinList", "POST", (res) => {
        this.clientList = res;
      });
    },
    // 负责人-输入框聚焦是清除其焦点
    focus(e) {
      e.srcElement.blur();
    },
    // 分配完
    updateStaffFlag(data) {
      // console.log(data,"dasds")
      if (data != 1) {
        this.onStaff = data;
      }
      this.staffFlag = false;
    },
    //获取时间
    getTime(time) {
      let current = timeOnj.filter((item) => {
        return item.key == time;
      });
      return current;
    },
    addTarget() {
      this.$router.push({
        path: "/scrm_pc/target/add",
      });
    },
    // 搜索
    onSearch() {
      this.pagination.current=1;
      this.getTask(this.key, this.type);
    },
    reset() {
      this.key = "";
      this.type = null;
      this.status = null;
      this.onStaff.userid = "";
      this.onStaff.name = "";
      this.getTask();
    },
    getTask() {
      let times = this.getTime(this.type);
      let data = {
        timetype: "",
        staff_id: this.onStaff.userid,
        type: 3,
      };
      if (times[0]?.key) {
        data.timetype = times[0]?.label;
      }

      requestXml(
        "/scrm/Target/show",
        "POST",
        (res) => {
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].start = getDateTime(res.list[i].start);
            res.list[i].end = getDateTime(res.list[i].end);
            res.list[i].status = this.taskStatus[Number(res.list[i].status)];
            res.list[i].type = this.taskType[Number(res.list[i].type - 1)];
            res.list[i].createtime = moment(res.list[i].createtime).format(
              "YYYY-MM-DD"
            );
            res.list[i].endtime = moment(res.list[i].endtime)
              .subtract(1, "days")
              .format("YYYY-MM-DD");
            res.list[i].createtime =
              res.list[i].createtime + " 至 " + res.list[i].endtime;
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;
        },
        {
          ...data,
          page: {
            curpage: this.pagination.current,
            // pagesize: 999,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      this.pagination.current = current;
      this.getTask(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTask(); //向后端发送请求
    },
    // 查看 type 1 查看 2 编辑
    showDetail(id, type = 1) {
      this.$router.push({
        path: "/scrm_pc/target/detail",
        query: {
          id: id,
          type: type,
        },
      });
    },
    // 编辑
    editTask(id) {
      this.$router.push({
        path: "/scrm_pc/task/taskAdd",
        query: {
          id: id,
        },
      });
    },
    // 关闭
    off(id) {
      requestXml(
        "/scrm/Task/setClose",
        "POST",
        (res) => {
          console.log(res);
          this.getTask();
        },
        { id: id }
      );
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/scrm/Target/personaldel",
        "POST",
        (res) => {
          console.log(res);
          this.getTask();
        },
        { id: id }
      );
    },
    delAll() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选择");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        this.onDelete(idArr);
      }
    },
  },
};
</script>
<style scoped>
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
</style>

